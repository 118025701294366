import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

function DataVoucherSixthSection() {
	const data = useStaticQuery(graphql`
		query {
			WithAIProfessional: file(
				relativePath: { eq: "images/dataVoucher/WithAIProfessional.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			SaftyLock: file(
				relativePath: { eq: "images/dataVoucher/SaftyLock.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			QuickResponse: file(
				relativePath: { eq: "images/dataVoucher/QuickResponse.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Gift: file(relativePath: { eq: "images/dataVoucher/Gift.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<Container>
				<SectionTitle>
					깊이 있는 프로젝트를 위한 최적의 데이터 가공
				</SectionTitle>
				<SectionContents>
					<ContentsLine>
						<Content>
							<img src={data.WithAIProfessional?.childImageSharp.fluid.src} />
							<ContentTextBox>
								<ContentTitle>AI 전문가와 함께</ContentTitle>
								<ContentDescription>
									의료/헬스케어 분야 전문 엔지니어가 AI와 데이터에 대한
									인사이트를 바탕으로, 리서치와 논문 탐구를 통한 심도 있는
									컨설팅을 제공합니다.
								</ContentDescription>
							</ContentTextBox>
						</Content>
						<Content>
							<img src={data.SaftyLock?.childImageSharp.fluid.src} />
							<ContentTextBox>
								<ContentTitle>안전한 데이터 관리 시스템</ContentTitle>
								<ContentDescription>
									의료 데이터 처리에 적합한 클라우드 보안 설계와 데이터 관리
									시스템으로, IRB 승인에 요구되는 다양한 기술적 지원이
									가능합니다.
								</ContentDescription>
							</ContentTextBox>
						</Content>
					</ContentsLine>
					<ContentsLine>
						<Content>
							<img src={data.QuickResponse?.childImageSharp.fluid.src} />
							<ContentTextBox>
								<ContentTitle>신속한 고객사 대응</ContentTitle>
								<ContentDescription>
									담당 AI 리서처와의 주기적인 논의를 통해, 복잡한 요구사항과
									데이터 환경에서도 최적의 결과를 이끌어냅니다.
								</ContentDescription>
							</ContentTextBox>
						</Content>
						<Content>
							<img src={data.Gift?.childImageSharp.fluid.src} />
							<ContentTextBox>
								<ContentTitle>AI 모델링 및 후속 연계 지원</ContentTitle>
								<ContentDescription>
									데이터바우처를 통해 구현된 가공데이터와 AI 모델 테스팅 환경을
									통해 솔루션 구현가능성(feasibility)을 확인하고, 실제 최적화
									솔루션 구축까지 이어질 수 있는 AI 바우처 연계를 지원합니다.
								</ContentDescription>
							</ContentTextBox>
						</Content>
					</ContentsLine>
				</SectionContents>
			</Container>
		</Block>
	)
}

const Block = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #f6f8f9;
	width: 100%;

	@media (min-width: 1280px) {
		padding: 184px 200px;
		gap: 10px;
		height: 1212px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		padding: 160px 32px;
		gap: 10px;
		height: 1232px;
	}
	@media (max-width: 688px) {
		padding: 128px 20px;
		gap: 72px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	@media (min-width: 1280px) {
		gap: 96px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		align-items: center;
		gap: 96px;
	}
	@media (max-width: 688px) {
		align-items: center;
		gap: 72px;
	}
`

const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	color: #141414;
	font-weight: 700;
	font-family: 'Inter', 'Pretendard';
	@media (min-width: 1280px) {
		font-style: normal;
		font-size: 52px;
		line-height: 64px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		font-style: normal;
		font-size: 48px;
		line-height: 56px;
	}
	@media (max-width: 688px) {
		font-style: normal;
		font-size: 40px;
		line-height: 48px;
	}
`

const SectionContents = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	@media (min-width: 1280px) {
		gap: 96px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		gap: 96px;
	}
	@media (max-width: 688px) {
		gap: 32px;
	}
`

const ContentsLine = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	@media (min-width: 1280px) {
		gap: 240px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		gap: 96px;
	}
	@media (max-width: 688px) {
		flex-direction: column;
		gap: 32px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 32px;

	img {
		width: 80px;
		height: 80px;
	}

	@media (min-width: 1280px) {
		width: 383px;
		height: 300px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		width: 294px;
		height: 300px;
	}
	@media (max-width: 688px) {
		width: 320px;
		height: 280px;
	}
`

const ContentTextBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;

	@media (min-width: 1280px) {
		width: 383px;
		height: 148px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		width: 294px;
		height: 168px;
	}
	@media (max-width: 688px) {
		width: 320px;
		height: 144px;
	}
`

const ContentTitle = styled.div`
	color: #141414;
	display: flex;
	align-items: center;
	font-family: 'Inter', 'Pretendard';

	@media (min-width: 1280px) {
		height: 40px;
		font-style: normal;
		font-weight: 700;
		font-size: 28px;
		line-height: 40px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		height: 40px;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
	}
	@media (max-width: 688px) {
		height: 32px;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
	}
`

const ContentDescription = styled.div`
	font-family: 'Inter', 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
	display: flex;

	@media (min-width: 1280px) {
		width: 383px;
		height: 84px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		width: 294px;
		height: 112px;
	}
	@media (max-width: 688px) {
		width: 320px;
		height: 112px;
	}
`

export default DataVoucherSixthSection
