import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import Watch from '../../assets/svg/Watch.svg'

function DataVoucherFourthSection() {
	const data = useStaticQuery(graphql`
		query {
			FourthSectionArticle1: file(
				relativePath: { eq: "images/dataVoucher/FourthSectionArticle1.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			FourthSectionArticle2: file(
				relativePath: { eq: "images/dataVoucher/FourthSectionArticle2.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			NaturalLanguageProcessing: file(
				relativePath: { eq: "images/dataVoucher/NaturalLanguageProcessing.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<ImageSide>
				<img src={data.FourthSectionArticle1?.childImageSharp.fluid.src} />
				<img src={data.FourthSectionArticle2?.childImageSharp.fluid.src} />
			</ImageSide>
			<TextSide>
				<SectionCaption>
					<Watch />
					<CaptionText>Natural Language Processing</CaptionText>
				</SectionCaption>
				<SectionTitle>
					<div>Ontologia:</div> <div>바이오/의료 텍스트 AI 가공</div>
				</SectionTitle>
				<SectionDescription>
					다양한 종류의 텍스트 모델을 바탕으로, 의료 데이터에 최적화된 AI 데이터
					제작과 모델 테스팅이 가능합니다. Transformer 기반의 모델을 통해 의학
					논문, 임상 텍스트 및 다양한 의료, 헬스케어, 바이오 분야의
					Publication을 데이터 수집 단계부터 모델링, 전문적 레이블링, 테스트
					환경까지 지원이 가능합니다.
				</SectionDescription>
			</TextSide>
		</Block>
	)
}

const Block = styled.section`
	position: relative;
	display: flex;
	background: #f6f8f9;

	@media (min-width: 1280px) {
		flex-direction: row;
		height: 915px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		flex-direction: column-reverse;
		align-items: center;
		height: 1226px;
		padding: 140px 32px;
		gap: 72px;
	}
	@media (max-width: 768px) {
		flex-direction: column-reverse;
		align-items: center;
		padding: 128px 20px;
		gap: 64px;
	}
`

const ImageSide = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1280px) {
		margin-left: 220px;
		width: 489px;
		padding-top: 185px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		width: 447.5px;
	}
	@media (max-width: 768px) {
		width: 306px;
		height: 386px;
	}
`

const TextSide = styled.div`
	@media (min-width: 1280px) {
		padding-top: 275px;
		margin-left: 137px;
		width: 483px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
	}
	@media (max-width: 768px) {
	}
`

const SectionCaption = styled.div`
	display: flex;
`

const CaptionText = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-weight: 700;
	font-size: 20px;
	padding-left: 8px;
	color: #1f57f7;
`

const SectionTitle = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 52px;
	padding-top: 32px;
`

const SectionDescription = styled.div`
	padding-top: 32px;
	font-family: 'Inter', 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
`

export default DataVoucherFourthSection
