import React from 'react'
import styled from 'styled-components'

function DataVoucherSeventhSection() {
	return (
		<Block>
			<TextContainer>
				<SectionTitle>
					의료, 헬스케어 AI는
					<br />
					테서와 함께하세요.
				</SectionTitle>
			</TextContainer>
		</Block>
	)
}

const Block = styled.section`
	@media (min-width: 1280px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 400px 218px;
		gap: 10px;
		height: 872px;
		background: #ffffff;
		flex: none;
		order: 5;
		align-self: stretch;
		flex-grow: 0;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		display: none;
	}
	@media (max-width: 768px) {
		display: none;
	}
`

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 48px;
	width: 1005px;
	height: 72px;
	flex: none;
	order: 0;
	flex-grow: 0;
`

const SectionTitle = styled.div`
	font-family: 'Aeonik', 'Pretendard', 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 80px;
	line-height: 88px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #141414;
	flex: none;
	order: 0;
	flex-grow: 0;
`

export default DataVoucherSeventhSection
