import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import Camera from '../../assets/svg/Camera.svg'

function DataVoucherThirdSection() {
	const data = useStaticQuery(graphql`
		query {
			Check: file(relativePath: { eq: "images/dataVoucher/Check.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ThirdSectionImage: file(
				relativePath: { eq: "images/dataVoucher/ThirdSectionImage.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ThirdSectionImageForTablet: file(
				relativePath: {
					eq: "images/dataVoucher/ThirdSectionImageForTablet.png"
				}
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ThirdSectionImageForMobile: file(
				relativePath: {
					eq: "images/dataVoucher/ThirdSectionImageForMobile.png"
				}
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<TextInformation>
				<SectionContainer>
					<SectionCaption>
						<Camera />
						<CaptionText>Image Segmentation</CaptionText>
					</SectionCaption>
					<SectionTitle>
						<div>2D/3D 이미지</div>
						<div>AI 데이터 가공</div>
					</SectionTitle>
					<SectionDescription>
						자체 보유한 Auto Segmentation 기술을 통해 AI 학습데이터를 쉽고
						빠르게 가공하며, 데이터바우처 지원사업만으로도 간단한 AI모델을
						테스트 할 수 있습니다. 딥러닝 학습모델을 기반으로, 전문적인 의료
						분야의 데이터도 보다 효과적인 가공이 가능합니다. CT, MRI 등 전문적인
						3D 의료영상, 병리 슬라이드 이미지, 3D 구강모델 등 다양한 데이터를
						다룹니다.
					</SectionDescription>
				</SectionContainer>
				<CheckListContainer>
					<CheckList>
						<img
							src={data.Check?.childImageSharp.fluid.src}
							style={{ width: '40px', height: '40px' }}
						/>
						<CheckListTitle>빠르고 정확한 AI 학습 데이터 제작</CheckListTitle>
						<CheckListDescription>
							2D 영상의 자동 Segmentation 자체 보유 기술을 활용하여, 빠르게 초기
							데이터를 제작하고 전문가의 검수를 통해 높은 품질의 데이터를
							생산하고, AI 테스팅을 통해 검증합니다.
						</CheckListDescription>
					</CheckList>
					<CheckList>
						<img
							src={data.Check?.childImageSharp.fluid.src}
							style={{ width: '40px', height: '40px' }}
						/>
						<CheckListTitle>3D Image Segmentation</CheckListTitle>
						<CheckListDescription>
							3D 이미지 분야 딥러닝 자동 Segmentation 기술을 보유하고 있으며, AI
							모델링에 최적화된 데이터셋 구축과 PoC 모델 및 테스트 환경 구축까지
							지원해드립니다.
						</CheckListDescription>
					</CheckList>
				</CheckListContainer>
			</TextInformation>
			<ImageInformation>
				<ThirdSectionImageForScreen
					src={data.ThirdSectionImage?.childImageSharp.fluid.src}
				/>
				<ThirdSectionImageForTablet
					src={data.ThirdSectionImageForTablet?.childImageSharp.fluid.src}
				/>
				<ThirdSectionImageForMobile
					src={data.ThirdSectionImageForMobile?.childImageSharp.fluid.src}
				/>
			</ImageInformation>
		</Block>
	)
}

const Block = styled.section`
	position: relative;
	display: flex;
	background: #ffffff;

	@media (min-width: 1280px) {
		padding-top: 160px;
		height: 1027px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		padding: 95px 40px;
		gap: 10px;
		height: 1368px;
	}
	@media (max-width: 768px) {
		padding: 128px 20px;
		gap: 64px;
		height: 1681px;
		flex-direction: column;
		align-items: center;
	}
`

const TextInformation = styled.div`
	display: flex;
	flex-direction: column;
	@media (min-width: 1280px) {
		margin-left: 220px;
		width: 588px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		justify-content: space-between;
	}
	@media (max-width: 768px) {
		justify-content: flex-start;
	}
`

const SectionContainer = styled.div``

const SectionCaption = styled.div`
	display: flex;
`

const CaptionText = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-weight: 700;
	font-size: 20px;
	padding-left: 8px;
	color: #1f57f7;
`

const SectionTitle = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-style: normal;
	font-weight: 700;
	padding-top: 32px;
	width: 100%;
	@media (min-width: 1280px) {
		display: flex;
		font-size: 40px;
		line-height: 52px;
		div {
			padding-right: 13px;
		}
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		display: flex;
		font-size: 36px;
		line-height: 44px;
		div {
			padding-right: 13px;
		}
	}
	@media (max-width: 768px) {
		font-size: 32px;
		line-height: 40px;
	}
`
const SectionDescription = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-style: normal;
	font-weight: 400;
	padding-top: 32px;
	padding-bottom: 22px;
	font-size: 20px;
	line-height: 28px;
	@media (min-width: 1280px) {
	}
	@media (min-width: 768px) and (max-width: 1280px) {
	}
	@media (max-width: 768px) {
	}
`

const CheckListContainer = styled.div``

const CheckList = styled.div`
	@media (min-width: 1280px) {
		padding-top: 58px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		padding-top: 50px;
	}
	@media (max-width: 768px) {
		padding-top: 48px;
	}
`

const CheckListTitle = styled.div`
	padding-top: 16px;
	font-family: 'Pretendard', 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	@media (min-width: 1280px) {
	}
	@media (min-width: 768px) and (max-width: 1280px) {
	}
	@media (max-width: 768px) {
	}
`

const CheckListDescription = styled.div`
	padding-top: 12px;
	font-family: 'Inter', 'Pretendard';
	font-style: normal;
	font-weight: 400;

	@media (min-width: 1280px) {
		font-size: 14px;
		line-height: 20px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		font-size: 16px;
		line-height: 24px;
	}
	@media (max-width: 768px) {
		font-size: 16px;
		line-height: 24px;
	}
`

const ImageInformation = styled.div``

const ThirdSectionImageForScreen = styled.img`
	@media (min-width: 1280px) {
		padding-top: 62px;
		padding-left: 28px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		display: none;
	}
	@media (max-width: 768px) {
		display: none;
	}
`
const ThirdSectionImageForTablet = styled.img`
	@media (min-width: 1280px) {
		display: none;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		position: absolute;
		width: 669px;
		height: 405px;
		left: 40px;
		top: 450px;
	}
	@media (max-width: 768px) {
		display: none;
	}
`
const ThirdSectionImageForMobile = styled.img`
	@media (min-width: 1280px) {
		display: none;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		display: none;
	}
	@media (max-width: 768px) {
		width: 339px;
		height: 449px;
	}
`

export default DataVoucherThirdSection
