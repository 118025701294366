import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import FloatingHeader from '../base/floatingHeader/FloatingHeader'

function DataVoucherIntroSection() {
	const data = useStaticQuery(graphql`
		query {
			FlyngMagician: file(
				relativePath: { eq: "images/dataVoucher/FlyngMagician.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ShakingMans: file(
				relativePath: { eq: "images/dataVoucher/ShakingMans.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			MinistryOfScienceAndICT: file(
				relativePath: { eq: "images/dataVoucher/MinistryOfScienceAndICT.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Kdata: file(relativePath: { eq: "images/dataVoucher/Kdata.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<FloatingHeader isDataVoucher />
			<TitleSubject>
				<div>
					<DataVoucherText>데이터바우처</DataVoucherText>
					테서와 함께 하세요!
				</div>
			</TitleSubject>
			<TitleDescription>
				의료 / 병원 / 헬스케어 AI 데이터 전문 가공 분석
			</TitleDescription>
			<FlyngMagician src={data.FlyngMagician?.childImageSharp.fluid.src} />
			<ShakingMans src={data.ShakingMans?.childImageSharp.fluid.src} />
			<MinistryOfScienceAndICT
				src={data.MinistryOfScienceAndICT?.childImageSharp.fluid.src}
			/>
			<Kdata src={data.Kdata?.childImageSharp.fluid.src} />
		</Block>
	)
}

const Block = styled.section`
	@media (min-width: 1280px) {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 777px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		height: 532px;
		padding-left: 40px;
		padding-bottom: 100px;
	}
	@media (max-width: 688px) {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-top: 30px;
		align-items: center;
		height: 657px;
	}
`

const TitleSubject = styled.div`
	@media (min-width: 1280px) {
		font-family: 'Inter', 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 72px;
		line-height: 88px;
		padding-top: 155px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-weight: 800;
		font-size: 60px;
		line-height: 72px;
	}
	@media (max-width: 688px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: 'Inter', 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 35px;
		line-height: 48px;
		width: 100%;
	}
`

const DataVoucherText = styled.div`
	color: #1f57f7;
`

const TitleDescription = styled.div`
	@media (min-width: 1280px) {
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		line-height: 36px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 25px;
		line-height: 30px;
		margin-top: 14px;
	}
	@media (max-width: 688px) {
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 28px;
		margin-top: 32px;
		margin-bottom: 332px;
	}
`

const FlyngMagician = styled.img`
	z-index: -1;
	@media (min-width: 1280px) {
		position: absolute;
		width: 763.82px;
		height: 546.54px;
		left: 0px;
		top: 15px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		position: absolute;
		width: 360.58px;
		height: 261.19px;
		left: 33.72px;
		top: 12px;
	}
	@media (max-width: 688px) {
		position: absolute;
		width: 262.44px;
		height: 186.45px;
		left: 0px;
		top: 35px;
	}
`

const ShakingMans = styled.img`
	z-index: -1;
	@media (min-width: 1280px) {
		position: absolute;
		left: 72.88%;
		right: 7.67%;
		top: 35.14%;
		bottom: 39.75%;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		display: none;
	}
	@media (max-width: 688px) {
		height: 123px;
		width: 179px;
		position: absolute;
		bottom: 105.7px;
	}
`

const MinistryOfScienceAndICT = styled.img`
	@media (min-width: 1280px) {
		position: absolute;
		height: 56px;
		left: 907px;
		right: 258px;
		top: 600px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		height: 44px;
		width: 213px;
		position: absolute;
		left: 52.34%;
		right: 19.92%;
		top: 86.09%;
		bottom: 5.64%;
	}
	@media (max-width: 688px) {
		display: none;
	}
`

const Kdata = styled.img`
	@media (min-width: 1280px) {
		position: absolute;
		height: 73px;
		left: 1176px;
		right: 110px;
		top: 591px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		height: 53px;
		width: 112px;
		position: absolute;
		left: 82.42%;
		right: 2.99%;
		top: 85.34%;
		bottom: 4.7%;
	}
	@media (max-width: 688px) {
		display: none;
	}
`

export default DataVoucherIntroSection
