import React, { useState } from 'react'
import styled from 'styled-components'

function DataVoucherContactSection() {
	const [contactFormData, setContactFormData] = useState({
		companyName: '',
		name: '',
		phoneNumber: '',
		knowRoute: '',
		email: '',
		inquiryContent: '',
	})

	const changeContactFormData = ({ target }) => {
		const newInputValue = {}
		newInputValue[target.name] = target.value
		setContactFormData({
			...contactFormData,
			...newInputValue,
		})
	}

	const submitDataVoucher = (event) => {
		if (
			Object.values(contactFormData)
				.map((value) => value.split(' ').join(''))
				.includes('')
		) {
			event?.preventDefault()
			alert('입력란을 모두 입력해주세요.')
		} else {
			try {
				fetch('https://formspree.io/f/mgebagyv', {
					method: 'POST',
					body: JSON.stringify(contactFormData),
				})
					.then((response) => response.json())
					.then((result) => alert(result))
				alert('접수가 완료되었습니다.')
			} catch (error) {
				alert('죄송합니다. 문의 접수를 한번 더 부탁드립니다.')
			}
		}
	}

	return (
		<Block id="DataVoucherContactPoint">
			<SectionDescriptionContainer>
				<MobileSectionTitle>
					<div>의료, 헬스케어 AI는</div>
					<div>테서와 함께하세요.</div>
				</MobileSectionTitle>
				<DescriptionTitle>연락하기</DescriptionTitle>
				<DescriptionContent>
					문의 내용을 남겨주세요.
					<br />
					최대한 신속하게 연락드리겠습니다.
				</DescriptionContent>
			</SectionDescriptionContainer>
			<ContactFormContainer>
				<form onSubmit={submitDataVoucher}>
					<Input
						id="companyName"
						type="text"
						name="companyName"
						placeholder="회사명을 입력해주세요."
						onChange={changeContactFormData}
					/>
					<Input
						id="name"
						type="text"
						name="name"
						placeholder="담당자 분의 성함을 입력해주세요."
						onChange={changeContactFormData}
					/>
					<Input
						id="phoneNumber"
						type="number"
						name="phoneNumber"
						placeholder="연락처를 입력해주세요."
						onChange={changeContactFormData}
					/>
					<Input
						id="knowRoute"
						type="text"
						name="knowRoute"
						placeholder="알게된 경로"
						onChange={changeContactFormData}
					/>
					<Input
						id="email"
						type="email"
						name="email"
						placeholder="이메일을 입력해주세요."
						onChange={changeContactFormData}
					/>
					<Textarea
						id="inquiryContent"
						name="inquiryContent"
						placeholder="문의 내용&#13;&#10;예) 조직검사 병리 이미지 데이터를 가지고 있는데, 판독 솔루션을 만들기 위한 데이터 가공이 필요합니다 / 임상 데이터를 바탕으로 데이터 전처리와 초기 예측 모델 구성을 해보고 싶어요."
						onChange={changeContactFormData}
					/>
					<Button>문의 접수</Button>
				</form>
			</ContactFormContainer>
		</Block>
	)
}

const Block = styled.section`
	display: flex;
	@media (min-width: 1280px) {
		flex-direction: row;
		align-items: flex-start;
		padding: 128px 218px 240px;
		gap: 35px;
		height: 1179px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		flex-direction: column;
		align-items: flex-start;
		padding: 140px 40px;
		gap: 32px;
		height: 1401px;
	}
	@media (max-width: 688px) {
		flex-direction: column;
		align-items: center;
		padding: 64px 20px;
		gap: 32px;
		height: 1183px;
	}
`

const MobileSectionTitle = styled.div`
	padding-bottom: 32px;
	@media (min-width: 1280px) {
		display: none;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		font-family: 'Inter', 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 52px;
		line-height: 64px;
	}
	@media (max-width: 688px) {
		font-family: 'Inter', 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 44px;
	}
`

const SectionDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (min-width: 1280px) {
		padding: 0px;
		gap: 40px;
		width: 383px;
		height: 652px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
	}
	@media (max-width: 688px) {
	}
`

const DescriptionTitle = styled.div`
	color: #141414;
	font-weight: 700;

	@media (min-width: 1280px) {
		width: 274px;
		height: 72px;
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-size: 64px;
		line-height: 72px;
		display: flex;
		align-items: center;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		font-family: 'Pretendard', 'Inter';
		font-style: normal;
		font-size: 40px;
		line-height: 52px;
	}
	@media (max-width: 688px) {
		display: none;
	}
`

const DescriptionContent = styled.div`
	color: #141414;

	@media (min-width: 1280px) {
		width: 383px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		display: flex;
		align-items: center;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		padding-top: 10px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
	}
	@media (max-width: 688px) {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
	}
`

const ContactFormContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const Input = styled.input`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px 24px;
	margin-bottom: 20px;
	gap: 10px;
	border: none;
	background: #e6e6e6;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;

	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::placeholder {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		align-items: center;
		color: #a2a2a2;
	}

	@media (min-width: 1280px) {
		width: 591px;
		height: 64px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		/* width: 567px; */
		width: 80vw;
		height: 64px;
	}
	@media (max-width: 688px) {
		width: 80vw;
		height: 60px;
	}
`
const Textarea = styled.textarea`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px 24px;
	margin-top: 16px;
	border: none;
	resize: none;
	gap: 10px;
	background: #e6e6e6;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;

	::placeholder {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 32px;
		display: flex;
		align-items: center;
		color: #a2a2a2;
		flex: none;
		order: 0;
		flex-grow: 1;
	}

	@media (min-width: 1280px) {
		width: 591px;
		height: 256px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		/* width: 567px; */
		width: 80vw;
		height: 256px;
	}
	@media (max-width: 688px) {
		width: 80vw;
		height: 312px;
	}
`

const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 20px;
	margin-top: 43px;
	margin-left: 22px;
	gap: 8px;
	width: 213px;
	height: 70px;
	background: #000000;
	border-radius: 32px;
	color: #ffffff;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
`

export default DataVoucherContactSection
