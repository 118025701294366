import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import Click from '../../assets/svg/Click.svg'

function DataVoucherFifthSection() {
	const data = useStaticQuery(graphql`
		query {
			ProcessedTranslation: file(
				relativePath: { eq: "images/dataVoucher/ProcessedTranslation.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ProcessedTranslationForTablet: file(
				relativePath: {
					eq: "images/dataVoucher/ProcessedTranslationForTablet.png"
				}
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ProcessedTranslationForMobile: file(
				relativePath: {
					eq: "images/dataVoucher/ProcessedTranslationForMobile.png"
				}
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<TextSide>
				<SectionCaption>
					<Click />
					<CaptionText>다양한 데이터 가공과 모델 처리</CaptionText>
				</SectionCaption>

				<SectionTitle>보다 최적화된 가공과 구현</SectionTitle>
				<SectionDescription>
					다양한 형태의 데이터 가공과 AI 모델링도 문제없습니다. 최근 진행되었던
					바우처 프로젝트 사례 일부를 소개합니다.
					<ul>
						<li>
							신체 2D 이미지를 기반으로 한신체 주요 계측점 탐지(Key-Point
							Detection)와 3D 재건
						</li>
						<li>
							안면 랜드마크(Facial Landmarks) 키포인트 디텍션 데이터 제작 및
							모델 구현
						</li>
						<li>임상시험 데이터(ClinicalTrials.gov) 가공을 통한 복합 예측</li>
						<li>
							장내미생물(Microbiome) 및 대사산물 검사 결과 기반의 비만예측
						</li>
					</ul>
				</SectionDescription>
			</TextSide>
			<ImageSide>
				<ProcessedTranslationForScreen
					src={data.ProcessedTranslation?.childImageSharp.fluid.src}
				/>
				<ProcessedTranslationForTablet
					src={data.ProcessedTranslationForTablet?.childImageSharp.fluid.src}
				/>
				<ProcessedTranslationForMobile
					src={data.ProcessedTranslationForMobile?.childImageSharp.fluid.src}
				/>
			</ImageSide>
		</Block>
	)
}

const Block = styled.section`
	position: relative;
	display: flex;

	@media (min-width: 1280px) {
		height: 865px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		height: 840px;
		align-items: center;
		justify-content: space-around;
		padding: 140px 32px 140px 40px;
		gap: 32px;
	}
	@media (max-width: 688px) {
		flex-direction: column;
		align-items: center;
		padding: 128px 20px;
	}
`

const TextSide = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1280px) {
		margin-left: 220px;
		width: 434px;
		padding-top: 168.5px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		width: 328px;
		height: 560px;
	}
	@media (max-width: 688px) {
	}
`

const SectionCaption = styled.div`
	display: flex;
	align-items: center;
`

const CaptionText = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-weight: 700;
	font-size: 20px;
	padding-left: 8px;
	color: #1f57f7;
`

const ImageSide = styled.div`
	@media (min-width: 1280px) {
		margin-left: 84px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		img {
			height: 551px;
		}
	}
	@media (max-width: 688px) {
		padding-top: 64px;
		img {
			width: 320px;
			height: 318px;
		}
	}
`

const ProcessedTranslationForScreen = styled.img`
	@media (min-width: 1280px) {
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		display: none;
	}
	@media (max-width: 688px) {
		display: none;
	}
`
const ProcessedTranslationForTablet = styled.img`
	@media (min-width: 1280px) {
		display: none;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
	}
	@media (max-width: 688px) {
		display: none;
	}
`
const ProcessedTranslationForMobile = styled.img`
	@media (min-width: 1280px) {
		display: none;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		display: none;
	}
	@media (max-width: 688px) {
	}
`

const SectionTitle = styled.div`
	font-family: 'Pretendard', 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 52px;
	padding-top: 32px;
`

const SectionDescription = styled.div`
	padding-top: 32px;
	font-family: 'Inter', 'Pretendard';
	font-style: normal;
	font-weight: 400;
	ul {
		padding-top: 30px;
		padding-left: 40px;
	}
	@media (min-width: 1280px) {
		font-size: 20px;
		line-height: 32px;
	}
	@media (min-width: 689px) and (max-width: 1279px) {
		font-size: 18px;
		line-height: 28px;
	}
	@media (max-width: 688px) {
		font-size: 16px;
		line-height: 28px;
	}
`

export default DataVoucherFifthSection
